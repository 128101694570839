//Main Nav
.top-bar{
  background-color: $turquoise;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  border-bottom: 3px solid #ffffff;
  z-index: 10;
  ul{
    background-color: $turquoise;
  }
  @include breakpoint(medium){
    background-color: transparent;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 100%;
    border-bottom: 0;
    ul{
      background-color: transparent;
    }
  }

  &.align-right{
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    @include breakpoint(medium){
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }
  }
}

.title-bar{
  background: #26578f; /* Old browsers */
  background: -moz-linear-gradient(left, #26578f 0%, #007491 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #26578f 0%,#007491 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #26578f 0%,#007491 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26578f', endColorstr='#007491',GradientType=1 ); /* IE6-9 */
  position: absolute;
  width: 100%;
  top: -3rem;
  right: 0;
  padding: 0.5rem 1rem;
}

.menu{
  ul.social a{
    padding: 0;
  }
  .social-header{
    padding: 0.7rem 1rem;
  }
  p{
    color: #ffffff;
    font-weight: 600;
    font-size: 110%;
  }
  &.vertical{
    width: 100%;
    padding: 0 1rem;
    @include breakpoint(medium){
      width: auto;
      padding: 0;
    }
  }
}

li.underline a{
  padding: 0.7rem 1rem;
  @include breakpoint(medium){
    padding: 0.7rem 0 0.7rem 2rem;
  }
}

.underline{
  border-bottom: 1.5px solid #ffffff;
}

//Side Bar Nav
.nolist {
  list-style: none;
}

.sidebar-wrapper{
  margin: 0 0.1rem 2rem;
  @include breakpoint(medium){
    margin: 0;
  }
}

.sidebar-wrapper nav>ul>li>a {
  color: #ffffff;
  font-size: 85%;
  font-weight: 600;
  text-decoration: none;
  display: block;
  padding: 0.5rem 1rem;
  background: $dark-blue;
  margin: 0.5rem 0;
  border-radius: $global-radius;
  &.active{
    background: $turquoise;
  }
}
.sidebar-wrapper nav>ul>li>a:hover {
  color: #fff;
  background: $turquoise;
}

.sidebar-wrapper nav>ul>li>a:focus{
  background: $turquoise;
}

.sidebar-wrapper nav>ul>li>a:first-child {
  margin: 0 0 0.5rem 0;
}

select#sub-menu {
  width: 100%;
  color: #000;
  margin: 0;
  border: 1.5px solid $turquoise;
  text-overflow: '';
  &:focus{
    border: 1.5px solid $dark-blue;
    -webkit-box-shadow: 3px 0px 6px 0px rgba(38,87,143,0.5);
    -moz-box-shadow: 3px 0px 6px 0px rgba(38,87,143,0.5);
    box-shadow: 3px 0px 6px 0px rgba(38,87,143,0.5);
  }
}

//Pagination
$pagination-circular-size: 16px; // controls font and circle size
$pagination-circular-color: $dark-blue;
$pagination-circular-disabled-color: $medium-gray;
$pagination-circular-border-thickness: 0;

.pagination-wrapper {
  margin-top: 1rem;
  text-align: center;
}

.pagination-circular li.current {
  border: $pagination-circular-border-thickness solid $pagination-circular-color;
  border-radius: 5000px;
  padding: 0.285em 0.8em;
  font-size: $pagination-circular-size;
}

.pagination .current{
  background: $dark-blue;
}

.pagination-circular li.disabled {
  border: $pagination-circular-border-thickness solid $pagination-circular-disabled-color;
  padding: 0.285em 0.8em;
  border-radius: 5000px;
  font-size: $pagination-circular-size;
}

.pagination-circular a {
  border-radius: 5000px;
  padding: 0.285em 0.8em;
  border: $pagination-circular-border-thickness solid $pagination-circular-color;
  font-size: $pagination-circular-size;
  text-decoration: none;
  font-weight: 600;
  color: $dark-blue;
}

.pagination-circular li:not(.disabled):hover a {
  background: $pagination-circular-color;
  color: $white;
}

.pagination-circular li a {
  transition: background 0.15s ease-in, color 0.15s ease-in;
}

