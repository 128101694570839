.select-view{
  width: 100%;
  border: 1.5px solid $turquoise;
  float: right;
  &:focus{
    border: 1.5px solid $turquoise;
    box-shadow: #007491 0px 0px 5px;
  }
  @include breakpoint(medium){
    width: 25%;
  }
}
.card-wrapper{
  background-color: #ffffff;
  border-radius: 5px 5px 0 0;
  border-bottom: 2.5px solid $dark-blue;
  stroke-linecap: round;
  padding-bottom: 0.5rem;
  margin-bottom: 2rem;
  .card-thumbnail{
    &:focus{
      outline: $dark-blue;
      -webkit-box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.7);
      -moz-box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.7);
      box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.7);
    }
    .image{
      width: 100%;
      height: 12rem;
      border-radius: $global-radius;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.listing-content{
  padding-bottom: 1rem;
}

.event-details{
  list-style: none;
  margin-left: 0;
  li{
    font-size: 90%;
    font-weight: 600;
    .fa{
      color: $turquoise;
      font-size: 110%;
      padding-right: 1rem;
      &.fa-map-marker{
        padding-right: 1.2rem;
        padding-left: 3px;
      }
    }
  }
}

.resource-card{
  text-decoration: none;
  .card-thumbnail.image-holder.placeholder{
    background: transparent;
    .image{
      opacity: 1;
    }
  }
}

.event-btns{
  display: inline-block;
  margin-top: 1rem;
}