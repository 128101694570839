.homepage{
  .side-bar{
    width: 100%;
    height: auto;
    padding: 1rem 1.5rem;
    background: $ric-grey;
    border-radius: $global-radius;
    img{
      border-radius: $global-radius;
      padding-bottom: 1rem;
    }
  }
  .quicklink{
    background: $fam-green;
    padding: 2rem 1.5rem 1rem;
    margin-top: 1.5rem;
    border-radius: $global-radius;
    img{
      margin-bottom: 1rem;
    }
    p{
      color: #ffffff;
    }
    a{
      text-decoration: none;
    }
  }
}


.image-holder{
  display: block;
  position: relative;
  border-radius: $global-radius;
  background-clip: padding-box;
  overflow: hidden;
  background-color: $turquoise;
  margin: 1rem 1rem;
  &:focus{
    -webkit-box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.5);
    -moz-box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.5);
    box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.5);
  }
  @include breakpoint(medium) {
    margin: 0 0 2rem 0;
  }
  .image{
    width: 100%;
    height: 12rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.95;
    transition: all .5s ease-in-out 0s;
    &:hover{
      opacity: 0.7;
      -webkit-transform: scale3d(1.5,1.5,1.5);
      transform: scale3d(1.5, 1.5, 1.5);
    }
  }
  .title{
    background: rgba(0, 116, 145, 0.8);
    -webkit-transition: all .5s ease-in-out 0s;
    transition: all .5s ease-in-out 0s;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    h4{
      color: #ffffff;
    }
  }
}

.image-holder .image::before{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0,0%,100%,.5);
  content: "";
  transition: transform .6s;
  transform: scale3d(1.9,1.4,1) rotate(45deg) translate3d(0,-110%,0);
}

.image-holder:hover .image::before{
  -webkit-transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
  transform: scale3d(1.9,1.4,1) rotate3d(0,0,1,45deg) translate3d(0,100%,0);
}