footer{
  padding-top: 4rem;
  position: relative;
  @include breakpoint(medium){
    padding-top: 6rem;
  }
  hr{
    clear: both;
    max-width: 75rem;
    height: 0;
    margin: 2.5rem auto;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid $turquoise;
    border-left: 0;
  }

  h3{
    text-align: center;
    @include breakpoint(medium){
      text-align: left;
    }
  }

  p{
    text-align: center;
    font-size: 100%;
    @include breakpoint(medium){
      text-align: left;
    }
  }

  .logo{
    @include breakpoint(large){
      width: 70%;
    }
  }
}
.footer-container{
  position: relative;
  background: #ffffff;
  padding: 4rem 0 0;
  a.ric-btn.full-width{
    @include breakpoint(medium){
      width: auto;
    }
  }
}

.footer-deco{
  background-image: url("../img/icons/corner-logo.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 0;
  padding-bottom: 124.25%;
  width: 100%;
  position: absolute;
  bottom: 60%;
  right: 0;
  z-index: -1;
  @include breakpoint(medium){
    padding-bottom: 54.25%;
    width: 50%;
  }
  @include breakpoint(large){
    padding-bottom: 26.25%;
    width: 25%;
  }
}

.footer-stroke{
  height: 5px;
  width: 100%;
  background: #26578f; /* Old browsers */
  background: -moz-linear-gradient(left, #26578f 0%, #007491 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #26578f 0%,#007491 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #26578f 0%,#007491 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26578f', endColorstr='#007491',GradientType=1 ); /* IE6-9 */
  position: absolute;
  top: 0;
  left: 0;
}

.numbers{
  font-weight: 700;
  color: $dark-blue;
}

.contact-info{
  ul{
    margin-left: 0;
    text-align: center;
    @include breakpoint(medium){
      text-align: left;
    }
    li{
      list-style: none;
      font-size: 100%;
    }
  }
}

.address{
  padding-top: 1.5rem;
}

.contact-info{
  padding: 1rem 0;
}

.join-list{
  padding: 1rem 0;
}

.footer-right{
  text-align: center;
  @include breakpoint(medium){
    text-align: right;
  }

  a{
    text-decoration: none;
    color: $ric-black;
    &:hover{
      color: $dark-blue;
      text-decoration: underline;
    }
  }
}