$paragraph-margin: 20px;
$blockquote-margin: $paragraph-margin * 1.5;
$font-weight-bold: 700;
$border-color: #DADADA;

/* Tables */
table {
  width: 100%;
  margin: $blockquote-margin 0;
  border-collapse: collapse;
}

table, td, th {
  border: 1px solid $border-color;
  text-align: left;
}

th {
  font-weight: $font-weight-bold;
}

th, td {
  padding: 15px;
}

/* Image Styles */
.content{
  img {
    display: block;
    max-width: 100%;
    margin: $blockquote-margin 0;
  }

  img.left,
  .captionImage.left,
  img.floatleft {
    float: left;
    margin-right: $blockquote-margin;
  }

  img.right,
  .captionImage.right,
  img.floatright {
    float: right;
    margin-left: $blockquote-margin;
  }
}

/* Responsive iFrame Embed */
.media {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;
  margin: $blockquote-margin 0;
}
.media iframe,
.media object,
.media embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.leftAlone + * {
  clear: left;
}
.rightAlone + * {
  clear: right;
}
img.center {
  text-align: center;
  margin: 0 auto;
  display: block;
}
img.leftAlone {
  float: left;
}
img.rightAlone {
  float: right;
}
img.left {
  float: left;
  margin: 0 10px 10px 0;
}
img.right {
  float: right;
  margin: 0 0 10px 10px;
}