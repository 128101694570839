//Typography
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

$base-font: 'Open Sans', Arial, sans-serif;
$base-size: 1; // rem value

$font-min-px: 12; // px value
$font-max-px: 18; // px value

$font-min: $font-min-px / ($base-size * 12); // = 0.875 or 14px when base-size is 1
$font-max: $font-max-px / ($base-size * 16); // = 1.25 or 20px when base-size is 1
@include breakpoint(small) { body { font-family: $base-font; font-size: #{$font-min}rem; } }
@include breakpoint(medium) { body { font-size: #{$base-size}rem; font-size: calc(#{$font-min}rem + (#{$font-max-px} - #{$font-min-px}) * ((100vw - 640px) / 800)); } }
@include breakpoint(xxlarge) { body { font-size: #{$font-max}rem; } }

body{
  font-family: $base-font;
}

h1{
  font-size: 150%;
  font-weight: 600;
  color: $ric-black;
  line-height: 1;
}

#hero-slider h1{
  font-size: 200%;
  color: #ffffff;
}

h2{
  font-size: 125%;
  font-weight: 600;
  color: $ric-black;
  @include breakpoint(medium){
    font-size: 115%;
  }
  @include breakpoint(large){
    font-size: 125%;
  }
}

h3{
  font-size: 115%;
  font-weight: 600;
  color: $ric-black;
  a{
    text-decoration: none;
    color: $ric-black;
    &:hover{
      text-decoration: underline;
    }
  }
}

.card-content h3{
  &:hover {
    text-decoration: underline;
    color: $dark-blue;
  }
}

.card-content a {
  text-decoration: none;
}


h4{
  font-size: 110%;
  font-weight: 600;
  color: $ric-black;
  letter-spacing: 0.5px;
}

h5{
  font-size: 110%;
  font-weight: 400;
  color: $ric-black;
}

p{
  font-size: 90%;
  font-weight: 400;
  color: $ric-black;
}

a{
  color: $dark-blue;
  text-decoration: underline;
}

li{
  font-size: 90%;
}

span{
  font-size: 90%;
}

blockquote{
  color: $dark-blue;
  font-style: italic;
}

figcaption{
  font-size: 80%;
  margin-bottom: 1rem;
}

a.ric-btn{
  display: inline-block;
  font-weight: 600;
  font-size: 90%;
  color: #ffffff;
  text-decoration: none;
  background-color: $turquoise;
  padding: 0.75rem 1.75rem;
  margin: 0 0 1rem 0;
  border: none;
  border-radius: 10px;
  text-align: center;
  line-height: 1;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  &.full-width{
    width: 100%;
  }
  &:hover{
    background-color: $dark-blue;
    color: #ffffff;
  }
  &:focus{
    background-color: $dark-blue;
    color: #ffffff;
    outline: 0;
    -webkit-box-shadow: 4px 4px 10px 0px rgba(38,87,143,0.5);
    -moz-box-shadow: 4px 4px 10px 0px rgba(38,87,143,0.5);
    box-shadow: 4px 4px 10px 0px rgba(38,87,143,0.5);
  }
}

.ric-btn, [type='submit']{
  display: inline-block;
  font-weight: 600;
  font-size: 90%;
  color: #ffffff;
  text-decoration: none;
  background-color: $turquoise;
  padding: 0.75rem 1.75rem;
  margin: 0 0 1rem 0;
  border: none;
  border-radius: 10px;
  text-align: center;
  line-height: 1;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  &:hover{
    background-color: $dark-blue;
    color: #ffffff;
  }
  &:focus{
    background-color: $dark-blue;
    color: #ffffff;
    outline: 0;
    -webkit-box-shadow: 4px 4px 10px 0px rgba(38,87,143,0.5);
    -moz-box-shadow: 4px 4px 10px 0px rgba(38,87,143,0.5);
    box-shadow: 4px 4px 10px 0px rgba(38,87,143,0.5);
  }
}

:focus{
  //outline: 1 !important;
  outline-color: #1779ba;
  //-webkit-box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.5);
  //-moz-box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.5);
  //box-shadow: 3px 3px 6px 0px rgba(38,87,143,0.5);
}

.top-right{
  p{
    color: #ffffff;
    font-weight: 600;
    margin-top: 0.80rem;
    font-size: 100%;
  }
}

#main-nav {
  a{
    color: #ffffff;
    font-weight: 600;
    font-size: 110%;
    text-decoration: none;
    &:hover{
      text-decoration: underline;
      color: #ffffff;
    }
    &:focus{
      text-decoration: underline;
      color: #ffffff;
      box-shadow: 0;
    }

    @include breakpoint(medium){
      font-size: 100%;
      color: $ric-black;
      &:hover{
        text-decoration: underline;
        color: $dark-blue;
      }
      &:focus{
        text-decoration: underline;
        color: $dark-blue;
        box-shadow: none;
      }
    }
    @include breakpoint(large){
      font-size: 110%;
    }
  }
}

.error.message{
  color: #d8272d;
  font-weight: 600;
  position: relative;
  top: -1rem;
}