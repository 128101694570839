//Colours
$turquoise: #007491;
$dark-blue: #26578F;
$ric-black: #191617;
$ric-grey: #F2F2F2;

//Family Advocacy Branding
$fam-green: #96C038;
$fam-purple: #220d3a;

.underlay{
  background: rgba(255, 255, 255, 0.7);;
  border-radius: $global-radius;
  z-index: 1;
}