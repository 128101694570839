//Header Search Input
$animated-search-form-initial-width: 10rem;
$animated-search-form-transition-duration: 0.4s;

.animated-search-form[type=search] {
  width: $animated-search-form-initial-width;
  border: none;
  box-shadow: 0 0 3.125rem rgba(0,0,0, 0.18);
  border-radius: $global-radius;
  background-image: url('//image.ibb.co/i7NbrQ/search_icon_15.png');
  background-position: 0.625rem 0.625rem;
  background-repeat: no-repeat;
  padding: 0.75rem 1.25rem 0.75rem 2rem;
  transition: width $animated-search-form-transition-duration ease-in-out;
  margin-top: 0.5rem;
  float: none;
  line-height: 1rem;

  &:focus {
    width: 100%;
  }
  @include breakpoint(medium){
    float: right;
  }
}

//Form
.contact-page{
  .contact-info{
    list-style: none;
    margin-left: 0;
    ul{
      text-align: left;
    }
    li{
      font-size: 90%;
    }
  }
}

.contact-label{
  color: $dark-blue;
  font-weight: 600;
}

.contact-form{
  .ric-btn{
    float: right;
  }
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea{
  border: 1.5px solid $turquoise;
  background: #ffffff;
  &:focus{
    border: 1.5px solid $dark-blue;
    -webkit-box-shadow: 3px 0px 6px 0px rgba(38,87,143,0.5);
    -moz-box-shadow: 3px 0px 6px 0px rgba(38,87,143,0.5);
    box-shadow: 3px 0px 6px 0px rgba(38,87,143,0.5);
  }
}

label{
  color: $ric-black;
  font-weight: 600;
  margin-bottom: 0.3rem;
}