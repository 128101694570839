//Header Styles
header{
  padding-bottom: 2rem;
  @include breakpoint(medium){
    padding-bottom: 4rem;
  }
}
.logo{
  width: 100%;
  padding: 1rem 2rem;
  @include breakpoint(medium){
    padding: 0;
  }
}

//Top Bar Styles
.top-right{
  position: relative;
  background: #26578f; /* Old browsers */
  background: -moz-linear-gradient(left, #26578f 0%, #007491 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #26578f 0%,#007491 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #26578f 0%,#007491 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26578f', endColorstr='#007491',GradientType=1 ); /* IE6-9 */
  height: 3.5rem;
}

.ric-right{
  float: right;
}

ul.top-header{
  margin-left: 0;
}

ul.top-header li{
  display: inline-block;
  list-style: none;
}

.phone{
  padding: 0 1rem;
  float: left;
  font-size: 100%;
}

.search{
  padding: 0 1rem;

  &--mobile {

    input {
      max-width: 190px;
      margin-right: 0.25rem;
    }

    button {
      margin-right: 0.15rem;
      background: white;
      width: 39px;
      height: 39px;
    }

  }

}

.social-header{
  float: right;
}

@media only screen and (max-width: 730px) {
  .social-header{
    img.icon{
      width: 2rem;
      padding: 0.7rem 0;
    }
  }
  .top-header{
    .search{
      width:200px;
      .animated-search-form[type=search]{
        float: left;
        &:focus{
          margin-right: 0;
        }
      }
    }
  }
}

ul.social {
  margin-left: 0;
  li{
    list-style: none;
    display: inline-block;
  }
}

img.icon {
  width: 2.5rem;
  height: auto;
  padding: 0.5rem 0.1rem;
}

.navigation{
  padding: 1.5rem 0;
  position: relative;
}


//Hero Slider Styles
.header-slider{
  position: relative;
}

#hero-slider{
  position: relative;
  height: 20rem;
  @include breakpoint(medium){
    height: 25rem;
  }

  .owl-prev {
    color: #FFF;
    font-size: 4rem;
    background: transparent;
    position: absolute;
    top: 50%;
    z-index: 1000;
    left: 2%;
    cursor: pointer;
    margin-top: -2rem;
    @include breakpoint(medium){
      font-size: 6rem;
      margin-top: -4rem;
    }
  }

  .owl-next {
    color: #FFF;
    font-size: 4rem;
    background: transparent;
    position: absolute;
    top: 50%;
    z-index: 1000;
    right: 2%;
    cursor: pointer;
    margin-top: -2rem;
    @include breakpoint(medium){
      font-size: 6rem;
      margin-top: -4rem;
    }
  }

  .owl-prev:hover,
  .owl-next:hover {
    opacity: 0.5;
  }
}
.owl-carousel{
  .item{
    height: 20rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @include breakpoint(medium){
      height: 25rem;
    }
  }
}

.owl-dots {
  position: absolute;
  bottom: 2rem;
  right: 61%;
  @include breakpoint(medium){
    right: 50%;
  }
  @include breakpoint(large){
    right: 28%;
  }
  @include breakpoint(xlarge){
    right: 31%;
  }
  @include breakpoint(xxlarge){
    right: 36%;
  }
}

.owl-theme .owl-dots .owl-dot span{
  background: #ffffff;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
  background: $ric-black;
}

.gradient-overlay{
  background: -moz-linear-gradient(left, rgba(0,116,145,0) 0%, rgba(0,116,145,0.9) 26%, rgba(0,116,145,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,116,145,0) 0%,rgba(0,116,145,0.9) 26%,rgba(0,116,145,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,116,145,0) 0%,rgba(0,116,145,0.9) 26%,rgba(0,116,145,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00007491', endColorstr='#007491',GradientType=1 ); /* IE6-9 */
  position: relative;
  float: right;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 4rem 4.5rem;
  @include breakpoint(medium){
    padding: 4rem 6rem;
    width: 70%;
  }
  @include breakpoint(large){
    width: 50%;
  }
}

.banner-copy {
  position: relative;
  z-index: 11;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  @include breakpoint(large){
    margin-left: 4rem;
    width: 50%;
  }
}


//Pages Header
.page-banner{
  position: relative;
  height: auto;
}
.banner{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 20rem;
}

.breadcrumb-container{
  width: 100%;
  background: rgba(242, 242, 242, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;
  .breadcrumbs li{
    font-size: 90%;
  }
}

.breadcrumbs{
  margin: auto;
  padding: 0.5rem 0;
  a{
    text-decoration: none;
    text-transform: none;
    color: $ric-black;
    &:hover{
      color: $dark-blue;
    }
    &:focus{
      color: $dark-blue;
    }
  }
  .current-page{
    text-transform: none;
    font-size: inherit;
    color: $dark-blue;
    font-weight: 600;
  }
;
}
